@import "../../layout";

.page {
  color: #ffffff;
  margin: 0 auto;
  background-image: url('./assets/planet.jpg');
  background-position: bottom center;
  background-size: cover;
}

.content {
  padding: 0 25px;
  margin: 0 auto;
  max-width: 1024px;
  box-sizing: border-box;
}

.header {
  display: flex;
  height: 360px;
  flex-direction: column;
  margin-bottom: 24px;
  max-height: calc(100vh - 65px);

  @include lg {
    height: 650px;
    max-height: calc(100vh - 110px);
  }
}

.flames {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 25px;
  box-sizing: border-box;
  background-image: url('./assets/flames.png');
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.logo {
  max-height: 50%;
  margin-bottom: 15px;

  @include lg {
    max-height: 40%;
    margin-bottom: 50px;
  }
}

.title {
  max-width: 500px;
  font-family: TrajanPro, serif;
  font-size: 30px;
  font-weight: bold;
  line-height: 1.41;
  text-align: center;
  color: #000000;


  @include lg {
    width: 635px;
    font-size: 44px;
  }
}

.strategy {
  text-align: center;
}

.benefits {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 48px;
  font-family: Lato, sans-serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 1.41;
  color: #e3c57d;

  > span {
    margin-right: 4px;
  }

  @include lg {
    flex-direction: row;
    margin-bottom: 96px;
  }
}

.goals {
  padding: 48px 0;
  margin-bottom: 48px;
  box-sizing: border-box;
  background: url('./assets/three.jpg');
  background-position: center center;
  background-size: cover;

  @include lg {
    padding: 96px 0;
    margin-bottom: 96px;
  }

  .content {
    display: flex;
    flex-direction: column;

    @include lg {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .item {
    margin-bottom: 28px;

    @include lg {
      width: 350px;
    }
  }

  .item:last-child {
    margin-bottom: 0;
  }
}

.line {
  display: inline-block;
  width: 50px;
  height: 1px;
  margin: 0 0 8px;
  border: 0;
  background-color: #e3c57d;
}

.topic {
  margin: 8px 0;
  font-family: TrajanPro, serif;
  font-size: 24px;
  color: #e3c57d;

  @include lg {
    font-size: 36px;
  }
}

.answer {
  font-family: Lato, sans-serif;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  line-height: 1.52;
  color: #e3c57d;

  @include lg {
    font-size: 24px;
  }
}

.products {
  margin-bottom: 42px;

  @include lg {
    margin-bottom: 96px;
  }

  .content {
    display: flex;
    flex-direction: column;

    @include lg {
      flex-direction: row;
      justify-content: space-around;
    }
  }

  .offer {
    margin-bottom: 24px;
    @include lg {
      flex: 1;
      max-width: 500px;
      margin-right: 50px;
      margin-bottom: 0;
    }
  }

  .pyramid {
    display: block;
    margin: 0 auto;
    max-width: 345px;
    @include lg {
      flex: 1;
    }
  }
}

.team {
  text-align: center;
  margin-bottom: 64px;

  @include lg {
    margin-bottom: 96px;
  }

  .topic {
    margin-bottom: 24px;

    @include lg {
      margin-bottom: 64px;
    }
  }

  .members {
    display: flex;
    justify-content: space-around;
  }

  .member {
    margin-right: 24px;

    > img {
      width: 100%;
      max-width: 223px;
      margin-bottom: 16px;
    }

    .name {
      font-family: Lato, sans-serif;
      font-size: 18px;
      font-weight: bold;
      color: #e3c57d;
    }

    .role {
      font-family: Lato, sans-serif;
      font-size: 18px;
      font-weight: 300;
      color: #e3c57d;
    }
  }

  .member:last-child {
    margin-right: 0;
  }
}

.feedback {
  text-align: center;
  margin: 0 auto 24px;
  max-width: 466px;

  .topic {
    margin-bottom: 24px;

    @include lg {
      margin-bottom: 32px;
    }
  }

  .form {
    text-align: left;

    .field {
      margin-bottom: 24px;
    }

    label {
      display: inline-block;
      font-family: Lato, sans-serif;
      font-size: 18px;
      font-weight: 300;
      margin-bottom: 8px;
    }

    input, textarea {
      width: 100%;
      height: 40px;
      padding: 8px 24px;
      box-sizing: border-box;
      font-size: 18px;
      color: white;
      border-radius: 4px;
      border: 1px solid white;
      background-color: rgba(0, 0, 0, 0.5);
    }

    textarea {
      height: 96px;
    }

    .btn {
      height: 40px;
      width: 100%;
      font-family: Lato, sans-serif;
      font-size: 18px;
      border-radius: 4px;
      color: #333333;
      background-color: #e3c57d;
    }
  }
}

.social {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 350px;
  > a {
    margin: 0 6px;
  }
}

/*
.triangle {
  width: 56px;
  height: 56px;
  background: #ff4369;
  border-top-left-radius: 12px;
  position: relative;
  overflow: hidden;
}

.triangle:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  background: #fff;
  transform: skew(-45deg);
}*/
