body {
  margin: 0;
  padding: 0;
  font-family: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

@font-face {
  font-family: 'TrajanPro';
  src: url('./layout/fonts/trajanPro/TrajanProRegular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'TrajanPro';
  src: url('./layout/fonts/trajanPro/TrajanProBold.ttf');
  font-weight: bold;
}
